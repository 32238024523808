<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>行业大类</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in liebiaoList" :key="index" @click="toTab(index)">
                        <div :class="{active:key==index}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>

                    <!-- <div class="pinpai-item" v-for="(item,index) in liebiaoList" :key="index" @click="toTab(index)">
                        <p :class="{active:key==index}">{{item.name}}</p>
                        <img src="../../../assets/images/right.png" alt="">
                    </div> -->
                    
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/gwkhxxk?hzy_id=10'}">外贸营销平台工具</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/gwkhxxk?hzy_id=10'}">国外客户信息库</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item :to="{path: '/msg_state?key=0'}">国家分区及排列</el-breadcrumb-item> -->
                        <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="shop-search-wrap">
                        <div class="shop-search">
                            <input type="text" v-model="search" placeholder="行业/国家">
                            <p @click="searchClick"><i class="el-icon-search"></i></p>
                        </div>                        
                    </div>
                    <div class="shop-table">
                        <el-table :data="tableList" stripe  style="width: 100%" >
                            <el-table-column  prop="pla_name" label="行业分类" min-width="30%">
                            </el-table-column>
                            <el-table-column  prop="coun_name" label="国家" min-width="20%">
                            </el-table-column>
                            <el-table-column  prop="name" label="公司名称" min-width="30%">
                            </el-table-column>
                            <el-table-column prop="address" label="公司地址" min-width="40%">
                            </el-table-column>
                            <el-table-column prop="link" label="官网" min-width="30%">
                            </el-table-column>
                            <el-table-column prop="full" label="联系人" min-width="20%">
                            </el-table-column>
                            <!-- <el-table-column prop="phone" label="电话" min-width="30%">
                            </el-table-column> -->
                        </el-table>
                    </div>

                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplates,getplatexts} from '../../../api/index'
    export default {
        data() {
            return {
                liebiaoList:[],
                key:0,
                tableList:[],
                id:'',
                name:'全部',
                search:''
            }
        },
        created() {
            this.id=this.$route.query.id
            this.name=this.$route.query.name
            console.log(this.$route.query.name)
            this.getplates()  
            
        },
        methods: {    
            // 跳转页面
            goTo(url,title) {
                this.$router.push({ 
                    path: url ,
                    query:{
                        title:title
                    }
                });
            },
            toTab(key){
                this.key=key;
                this.search='';
                this.getplates()
            },
            //获取列表
            getplates(){
                getplates({
                    p_id:10,
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data
                        this.getplatexts(res.data[this.key].id)
                    }
                })
            },
            //获取表格
            getplatexts(plate_id){
                console.log(plate_id)
                getplatexts({
                    plate_id:plate_id,
                    coun_id:this.id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.tableList=res.data.data
                        
                    }

                })
            },
            //搜索
            searchClick(){
                if (this.search=='') {
                    return false
                }
                // console.log(plate_id)
                getplatexts({
                    plate_id:10,
                    search:this.search,
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.tableList=res.data.data
                        
                    }

                })
            }    
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            width: 80%;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 30px 20px  50px 20px; 
                .shop-search-wrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 25px;
                    .shop-search{
                        height: 36px;
                        display: flex;
                        align-items: center;
                        border: 1px solid #CECECE;
                        padding-left: 20px;                    
                        border-radius:20px;
                        input{
                            background:none;outline:none;border:0px;
                            font-size: 16px;
                        }
                        p{font-size: 20px;color: #666;cursor: pointer;padding-right:20px;padding-left: 10px;}
                    }
                    .shop-search-p{
                        font-size: 16px;color: #FF7048;cursor: pointer;
                        i{padding-left: 10px;}
                    }
                }
                .shop-table{width: 100%;}
                /deep/ .el-table th{background: #DAE6F8;color: #000;font-size: 18px;font-weight: 500;}
                /deep/ .el-table td{font-size: 16px;color: #000;}
                /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{background: #F2F2F2;}               
            }
        }
        
    }

</style>